import React, { useEffect } from "react";
import { Logo } from "../assets/icons/Icons";
import { useUserData } from "../lib/contexts/UserContext";
import HospitalCard from "../components/HospitalCard";
import Loader from "../components/Loader";
import { useHospDocData } from "../lib/contexts/HospitalDoctorContext";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const ChooseDoctors = () => {
  const { userData } = useUserData();
  const { hospitalID, allDoctorsData, doctors, setDoctors } = useHospDocData();
  const navigate = useNavigate();

  useEffect(() => {
    setDoctors([]);
  }, []);

  return (
    <div className="flex flex-col w-full justify-center items-center pt-14 pb-40">
      <Logo />
      <p className="font-semibold text-dark text-lg md:text-2xl lg:text-3xl mt-16 mb-5">
        Choose doctors
      </p>
      <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-7 mb-16">
        {/* Create constant */}
        {allDoctorsData?.map((doctor) => (
          <HospitalCard
            key={doctor.doctor_id}
            logo={doctor.profile_picture}
            name={doctor.full_name}
            docCount={"none"}
            handlePress={() => {
              console.log(doctor.full_name);
              setDoctors((prevDoctors = []) => {
                // Check if the doctor is already in the array
                const isDoctorPresent = prevDoctors.some(
                  (d) => d.doctor_id === doctor.doctor_id
                );
                // If not present, add the doctor to the array
                if (!isDoctorPresent) {
                  return [...prevDoctors, doctor];
                }
                return prevDoctors;
              });
            }}
          />
        ))}
      </div>
      {doctors && doctors.length > 0 && (
        <div className="flex flex-col items-center space-y-5">
          <p className="font-semibold text-dark text-lg md:text-2xl lg:text-3xl">
            Selected doctors
          </p>
          <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-7 mb-16">
            {doctors?.map((doctor) => (
              <HospitalCard
                key={doctor.doctor_id}
                logo={doctor.profile_picture}
                name={doctor.full_name}
                docCount={"none"}
                handlePress={() => {
                  console.log(doctor.full_name);
                  setDoctors((prevDoctors = []) =>
                    prevDoctors.filter((d) => d.doctor_id !== doctor.doctor_id)
                  );
                }}
              />
            ))}
          </div>
          <button
            className="bg-white shadow-md rounded-lg hover:opacity-70 px-10 py-3 !mt-10"
            onClick={() => {
              if (doctors.length < 2)
                toast.error("Please select more than 1 doctor.");
              else navigate("/" + hospitalID + "/column-view");
            }}
          >
            Next -{">"}
          </button>
        </div>
      )}

      {userData === undefined && <Loader />}
    </div>
  );
};

export default ChooseDoctors;
