import React from "react";

const ScrollingText = () => {
  return (
    <div className="fixed bottom-0 w-full bg-kangaroo text-white py-3 overflow-hidden">
      <div className="flex items-center whitespace-nowrap animate-marquee">
        <p className="font-bold text-3xl">
          Enhance your experience by downloading our app from the Play Store or
          App Store. Visit our website{" "}
          <span className="underline">https://kangaroocareindia.com</span>.
        </p>
      </div>
    </div>
  );
};

export default ScrollingText;
