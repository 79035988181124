import React from "react";
import { Route, Routes } from "react-router-dom";
import Signin from "./screens/Signin";
import Carousel from "./screens/Carousel";
import ChooseHosp from "./screens/ChooseHosp";
import ChooseView from "./screens/ChooseView";
import ColumnView from "./screens/ColumnView";
import ChooseDoctors from "./screens/ChooseDoctors";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Signin />} />
      <Route path="/admin" element={<ChooseHosp />} />
      <Route path="/:hospital_id" element={<ChooseView />} />
      <Route path="/:hospital_id/choose-doctors" element={<ChooseDoctors />} />
      <Route path="/:hospital_id/carousel-view" element={<Carousel />} />
      <Route path="/:hospital_id/column-view" element={<ColumnView />} />
    </Routes>
  );
};

export default AppRoutes;
